
#slider-tooltip .ant-tooltip {
  bottom: 1px;
  padding-bottom: 20px;
  z-index: 1 !important;
}

#slider-tooltip .ant-tooltip-inner {
  user-select: none;
  padding: 0 8px;
  height: 100%;
  font-size: 12px;
  min-height: unset;
  background-color: #675bf5;
  box-shadow: none;
}

#slider-tooltip .ant-tooltip-arrow-content {
  background-color: #675bf5;
  margin-top: 10px;
}

#slider-tooltip .ant-tooltip-arrow::before {
  background-color: #675bf5;
  bottom: 0;
  margin-top: 11px;
}
