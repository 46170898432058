.ant-btn {
  font-weight: 600;
  padding: 10px 20px;
  font-size: 16px;
  line-height: 16px;
  min-height: 44px;
  align-items: center;
}

.ant-btn.ant-btn-lg {
  padding: 12px 30px;
  font-size: 18px;
  line-height: 18px;
  min-height: 54px;
}

.golden-btn {
  border: 1px solid #FEC669;
  background: #FEC669;
}

.golden-btn:hover, .golden-btn:focus {
  color: #121324;
  border: 1px solid #FEB02F;
  background: #FEB02F;
}

.ant-btn.bg-primary-dark:hover, .ant-btn.bg-primary-dark:focus {
  background: #595959;
  border: 1px solid #595959;
}
