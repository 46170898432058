h1 {
  font-weight: 600 !important;
  font-size: 46px !important;
  line-height: 54px !important;
}

h2 {
  font-weight: 600 !important;
  font-size: 38px !important;
  line-height: 46px !important;
}

h3 {
  font-weight: 600 !important;
  font-size: 30px !important;
  line-height: 38px !important;
}

h4 {
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 32px !important;  
}

h5 {
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 28px !important;
}

h6 {
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 30px !important;
}
