.ant-menu-sub .ant-menu-item {
  margin-bottom: 4px !important;
}

.max-width-1200 {
  max-width: 1200px;
}

.user-panel .ant-drawer-content-wrapper {
  width: 480px !important;
}

.ant-modal-mask {
  background: rgba(18, 19, 36, 0.9) !important;
}

.mega-menu {
  top: 72px !important;
  right: 0 !important;
  left: 0 !important;
  max-width: 800px;
}

.custom-shadow {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15) !important;
}

.mega-menu .ant-menu:not(.ant-menu-hidden) {
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 4px !important;
}

.user-menu {
  top: 70px !important;
}

.overlay-helper .ant-popover-content .ant-popover-inner {
  max-width: 224px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}

.table-blocker .ant-spin-spinning {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.legalities .ant-modal {
  max-width: 800px !important;
  width: 100% !important;
}

.legalities {
  display: flex;
  align-items: center;
}