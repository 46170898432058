/* ibm-plex-sans-regular - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/ibm-plex-sans-v7-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Sans'), local('IBMPlexSans'),
       url('./fonts/ibm-plex-sans-v7-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/ibm-plex-sans-v7-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/ibm-plex-sans-v7-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/ibm-plex-sans-v7-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/ibm-plex-sans-v7-latin-regular.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}
/* ibm-plex-sans-600 - latin */
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/ibm-plex-sans-v7-latin-600.eot'); /* IE9 Compat Modes */
  src: local('IBM Plex Sans SemiBold'), local('IBMPlexSans-SemiBold'),
       url('./fonts/ibm-plex-sans-v7-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/ibm-plex-sans-v7-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/ibm-plex-sans-v7-latin-600.woff') format('woff'), /* Modern Browsers */
       url('./fonts/ibm-plex-sans-v7-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/ibm-plex-sans-v7-latin-600.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

body {
  font-family: IBM Plex Sans;
}

.fs-12 {
  font-size: 12px;
  line-height: 20px;
}

.fs-14 {
  font-size: 14px;
  line-height: 22px;
}

.fs-16 {
  font-size: 16px;
  line-height: 24px;
}

.fs-18 {
  font-size: 18px;
  line-height: 30px;
}

.fs-20 {
  font-size: 20px;
  line-height: 28px;
}

.subheading {
  font-size: 24px;
  line-height: 32px;
}

.text-muted {
  color: #8C8C8C !important;
}

.text-grey-4 {
  color: #E8E8E8;
}

.text-grey-5 {
  color: #D9D9D9;
}

.text-dark {
  color: #262626 !important;
}

.text-danger {
  color: #FF4D4F !important;
}

.text-primary {
  color: #675BF5 !important;
}