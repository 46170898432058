#error-msg .ant-message-notice-content {
  background: #fff1f0;
  border: 1px solid #ffccc7;
  font-size: 14px;
}

#success-msg .ant-message-notice-content {
  background: #f6ffed;
  border: 1px solid #b7eb8f;
  font-size: 14px;
}

#warning-msg .ant-message-notice-content {
  background: #fffbe6;
  border: 1px solid #ffe58f;
  font-size: 14px;
}

#existing-pro .ant-modal {
  width: 100% !important;
  padding: 0;
  max-width: 480px;
  background: tomato;
}
#existing-pro .anticon {
  display: none;
}
#existing-pro .ant-modal-confirm-btns {
  text-align: center;
  float: unset;
}
#existing-pro .ant-modal-body {
  padding: 30px;
}

#global-search > div > div > .ant-select-dropdown {
  z-index: 1 !important;  
}
