.ant-input,
.ant-select {
  border-radius: 4px;
  font-size: 16px !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}

.ant-select .ant-select-selector {
  border-radius: 4px !important;
}

.ant-select-item-option-content {
  font-size: 16px;
}

.ant-form .ant-form-item .ant-form-item-label > label,
.ant-form .ant-form-item .ant-form-item-control > .ant-form-item-explain {
  font-size: 14px !important;
}
